body {
	font-family: 'Open Sans', sans-serif;
}

a {
	text-decoration: none !important;
	outline: none;
}

p {
	font-size: 16px;
	line-height: 26px;
}

ul {
	list-style: none;
	padding: 0;
}

.main-con {
	max-width: 100%;
}

.main-box {
	padding: 45px 0px 45px 0px;
	text-align: center;
}

h2 {
	margin-bottom: 30px;
	font-weight: 700;
	text-transform: uppercase;
	color: #232323;
}

.btn-default {
	display: inline-block;
	padding: 10px 30px !important;
	background-color: #20257c !important;
	color: #fff !important;
	border-radius: 5px;
	transition: .3s ease;
	-webkit-transition: .3s ease;
	-moz-transition: .3s ease;
	-ms-transition: .3s ease;
	margin-top: 30px;
}

.btn-default:hover {
	-webkit-transform: translateY(-5px);
	transform: translateY(-5px);
	transition: .3s ease;
	-webkit-transition: .3s ease;
	-moz-transition: .3s ease;
	-ms-transition: .3s ease;
}

.btn-default:before {
	pointer-events: none;
	position: absolute;
	z-index: -1;
	content: '';
	top: 100%;
	left: 5%;
	height: 10px;
	width: 90%;
	opacity: 0;
	background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
	background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-property: transform, opacity;
	transition-property: transform, opacity;
}

.btn-default:hover:before {
	opacity: 1;
	-webkit-transform: translateY(5px);
	transform: translateY(5px);
}

.navbar {
	padding: 0;
}

/*header starts*/
.top-inner ul {
	margin-bottom: 0px;
	display: flex;
	justify-content: flex-end;
	-webkit-justify-content: flex-end;
	-moz-justify-content: flex-end;
	-ms-justify-content: flex-end;
}

.top-header {
	background-color: #20257c;
}

.top-header ul li,
.top-header ul li a {
	color: #fff;
	font-size: 14px;
}

.top-header ul li a {
	font-size: 14px;
	padding: 4px 5px;
	display: inline-block;
}

.top-header ul li:first-child {
	margin-right: 15px;
}

.navbar-light .navbar-nav .nav-link {
	color: #000;
	padding: 21px 15px 21px 15px !important;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
	background-color: #20257C;
	color: #fff !important;
}

.head-con.fixed-nav .top-header {
	position: fixed;
	top: 0;
	width: 100%;
	left: 0;
	z-index: 99;
}
.navbar{
	padding: 0 !important;
}

/*header ends*/






/*css for toggle header*/



.navbar-light .navbar-toggler-icon {
	background-image: none;
}

.navbar-light .navbar-toggler-icon {
	position: relative;
}

.navbar-light .navbar-toggler-icon em {
	width: 30px;
	height: 2px;
	display: inline-block;
	background-color: #20257C;
	position: absolute;
	top: 14px;
	left: 0;
	transition: .3s ease;
	-webkit-transition: .3s ease;
	-moz-transition: .3s ease;
	-ms-transition: .3s ease;
}

.navbar-light .navbar-toggler-icon em:before,
.navbar-light .navbar-toggler-icon em:after {
	content: "";
	position: absolute;
	width: 30px;
	height: 2px;
	left: 0;
	position: absolute;
	top: 0;
	right: 0;
	background-color: #20257C;
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
	-o-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition: -webkit-transform .3s;
	-moz-transition: -moz-transform .3s;
	transition: transform .3s;
}

.navbar-light .navbar-toggler-icon em:before {
	-webkit-transform: translateY(-8px) rotate(0deg);
	-moz-transform: translateY(-8px) rotate(0deg);
	-ms-transform: translateY(-8px) rotate(0deg);
	-o-transform: translateY(-8px) rotate(0deg);
	transform: translateY(-8px) rotate(0deg);
}

.navbar-light .navbar-toggler-icon em:after {
	-webkit-transform: translateY(8px) rotate(0deg);
	-moz-transform: translateY(8px) rotate(0deg);
	-ms-transform: translateY(8px) rotate(0deg);
	-o-transform: translateY(8px) rotate(0deg);
	transform: translateY(8px) rotate(0deg);
}

.navbar-light .navbar-toggler {
	border: none;
	outline: none;
}

.navbar-toggler.open .navbar-toggler-icon em:before {
	-webkit-transform: translateY(0) rotate(45deg);
	-moz-transform: translateY(0) rotate(45deg);
	-ms-transform: translateY(0) rotate(45deg);
	-o-transform: translateY(0) rotate(45deg);
	transform: translateY(0) rotate(45deg);
}

.navbar-toggler.open .navbar-toggler-icon em:after {
	-webkit-transform: translateY(0) rotate(-45deg);
	-moz-transform: translateY(0) rotate(-45deg);
	-ms-transform: translateY(0) rotate(-45deg);
	-o-transform: translateY(0) rotate(-45deg);
	transform: translateY(0) rotate(-45deg);
}

.navbar-toggler.open .navbar-toggler-icon em {
	width: 0px;
	transition: .3s ease;
	-webkit-transition: .3s ease;
	-moz-transition: .3s ease;
	-ms-transition: .3s ease;
}



/*.header.fixed-nav .navbar-light .navbar-toggler-icon em,.header.fixed-nav .navbar-light .navbar-toggler-icon em:before,.header.fixed-nav .navbar-light .navbar-toggler-icon em:after{
  background-color: #EE8B04;
}*/



/*css fro toggle header ends*/



/*banner starst*/
.main-banner .owl-dots {
	position: absolute;
	width: 100%;
	left: 0;
	bottom: 10px;
	text-align: center;
}

.main-banner .owl-dots span {
	display: inline-block;
	width: 30px;
	height: 7px;
	background-color: #373fcb;
	margin: 0px 9px;
	border-radius: 10px;
}

.main-banner .owl-dots .owl-dot.active span {
	background-color: #20257c;
}

.main-banner .owl-dots button {
	outline: none;
}

/*banner ends*/

/*about starts*/
.app-con {
	background-color: #e6e7f9;
	max-width: 1920px;
	margin: 0 auto;
}

.app-inner {
	display: flex;
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
}

.app-inner>div {
	width: 33.33%;
	display: inline-flex;
	align-items: center;
	-webkit-align-items: center;
	-moz-align-items: center;
	-ms-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-moz-justify-content: center;
	-ms-justify-content: center;
	height: 150px;
}

.app-inner>div p {
	color: #fff;
	max-width: 70%;
}

.app-inner>div:nth-child(odd) {
	background-color: #3139bf;
}

.app-inner>div:nth-child(even) {
	background-color: #20257C;
}

.app-box span {
	max-width: 50px;
	margin-right: 10px;
}

.app-con .btn-default {
	margin-top: 45px;
}

/*about ends*/






/*advantage starts*/
.advant-inner {
	text-align: center;
}

.advant-inner img {
	max-width: 40px;
}

.advant-inner h5 {
	font-size: 16px;
	font-weight: 700;
	margin-top: 10px;
}

.advant-page .row>div {
	text-align: center;
	margin-bottom: 35px;
}

/*advantage ends*/

/*footer starst*/
.footer-con {
	background-color: #20257C;
	padding-top: 40px;
	text-align: center;
	background-image: url("../img/footer-bg.png");
	background-repeat: no-repeat;
	background-size: cover;
}

.copy-con {
	background-color: #0f1139;
}

.copy-con p {
	margin-bottom: 0px;
	text-align: center;
	padding: 5px 0px;
}

.copy-con p,
.copy-con a {
	color: #fff;
	font-size: 12px;
}

.social-con {
	display: flex;
}

.social-con li a {
	display: inline-flex;
	align-items: center;
	-webkit-align-items: center;
	-moz-align-items: center;
	-ms-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-moz-justify-content: center;
	-ms-justify-content: center;
	width: 30px;
	height: 30px;
	background-color: #fff;
	border-radius: 50%;
	color: #20257C;
	margin: 0px 10px;
}

.footer-links a {
	color: #fff;
}

.footer-inner {
	padding-bottom: 25px;
}

.footer-links li {
	margin-bottom: 3px;
}

/*footer ends*/


/*about page strarts*/
.inner-banner {
	height: 300px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.about-banner {
	background-image: url("../img/banner.jpg")
}

/*about page ends*/

/*product starst*/
.product-table img {
	border: 2px solid #000;
}

.product-con h3 {
	text-transform: uppercase;
	color: #20257c;
	font-weight: 700;
	margin-bottom: 30px;
	font-size: 36px;
}

.product-banner {
	background-image: url("../img/product-banner.jpg");
}

/*product ends*/

/*application starts*/
.application-box {
	border: 5px solid #ededed;
	border-radius: 10px;
	height: 250px;
	margin-bottom: 30px;
	display: flex;
	align-items: center;
	-webkit-align-items: center;
	-moz-align-items: center;
	-ms-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-moz-justify-content: center;
	-ms-justify-content: center;
	flex-direction: column;
	-webkit-box-shadow: 0px 0px 10px 0px rgba(247, 247, 247, 0.75);
	-moz-box-shadow: 0px 0px 10px 0px rgba(247, 247, 247, 0.75);
	box-shadow: 0px 0px 10px 0px rgba(247, 247, 247, 0.75);
	padding: 15px;
	transition: .5s ease;
	-webkit-transition: .5s ease;
	-moz-transition: .5s ease;
	-ms-transition: .5s ease;
}

.application-box span {
	background-color: #e5e6f8;
	padding: 15px;
	border-radius: 50%;
	margin-bottom: 15px;
	transition: .5s ease;
	-webkit-transition: .5s ease;
	-moz-transition: .5s ease;
	-ms-transition: .5s ease;
}

.application-box p {
	font-weight: 600;
	color: #000;
	transition: .5s ease;
	-webkit-transition: .5s ease;
	-moz-transition: .5s ease;
	-ms-transition: .5s ease;
}

.application-box:hover {
	background-color: #20257C;
	transition: .5s ease;
	-webkit-transition: .5s ease;
	-moz-transition: .5s ease;
	-ms-transition: .5s ease;
}

.application-box:hover span {
	border-radius: 5px;
	background-color: #fff;
	transition: .5s ease;
	-webkit-transition: .5s ease;
	-moz-transition: .5s ease;
	-ms-transition: .5s ease;
}

.application-box:hover p {
	color: #fff;
	transition: .5s ease;
	-webkit-transition: .5s ease;
	-moz-transition: .5s ease;
	-ms-transition: .5s ease;
}

.app-banner {
	background-image: url("../img/app-banner.jpg");
}

.advant-banner {
	background-image: url("../img/advant-banner.jpg")
}

/*application ends*/

/*advantage strats*/
.app-con h3 {
	text-transform: uppercase;
	color: #20257c;
	font-weight: 700;
	margin-bottom: 20px;
	font-size: 30px;
}

.app-con ol {
	text-align: left;
	padding-left: 30px;
	margin-top: 30px;
}

.app-con ol li {
	margin-bottom: 6px;
}

/*advantage ends*/

/*contact starst*/
.contact-banner {
	background-image: url("../img/contact-banner.jpg");
}

.contact-form {
	background-color: #fff;
	margin-top: -155px;
	padding: 45px 30px;
	border-radius: 10px;
	-webkit-box-shadow: 0px 0px 17px 0px rgba(32, 37, 124, 0.2);
	-moz-box-shadow: 0px 0px 17px 0px rgba(32, 37, 124, 0.2);
	box-shadow: 0px 0px 17px 0px rgba(32, 37, 124, 0.2);
}

.form-group {
	margin-bottom: 20px;
}

.form-control {
	box-shadow: none;
	height: 45px;
	box-shadow: none !important;
}

textarea.form-control {
	min-height: 150px;
}

.map-list li {
	text-align: left;
	display: flex;
	margin-bottom: 30px;
	color: #000;
	font-weight: 600;
	margin-top: 2px;
}

.map-list li a {
	color: #000;
}

.map-list li a:hover,
.map-list li a:focus {
	color: #20257C;
}

.map-list i {
	display: inline-block;
	font-size: 30px;
	color: #20257c;
	margin-right: 10px;
	margin-top: 0px;
}

.map-list li .fa-phone {
	font-size: 22px;
}

.map-list li .fa-envelope-o {
	font-size: 22px;
}

.contact-form h2,
.contact-form form {
	text-align: left;
}

.dropdown-toggle {
	position: relative;
}

.navbar-nav .dropdown-menu {
	margin: 0px;
	position: absolute;
	left: 50%;
	transform: translate(-50%);
	-webkit-transform: translate(-50%);
	-moz-transform: translate(-50%);
	-ms-transform: translate(-50%);
	padding: 0;
	border-radius: 0px;
	margin: 0;
	top: 65px;
}

.dropdown-item {
	padding: 10px 1.5rem;
}

.navbar-expand-lg .dropdown-item:hover,
.navbar-expand-lg .dropdown-item:focus {
	background-color: #20257C;
	color: #fff;
}

/*contact ends*/

/*investent starst*/
.side-bar li a {
	display: block;
	width: 100%;
	background-color: #3139bf;
	color: #fff;
	padding: 8px 5px 8px 15px;
	border-bottom: 1px solid #161954;
	margin-bottom: 5px;
}

.side-bar li a.active,
.side-bar li a:hover,
.side-bar li a:focus {
	background-color: #20257c;
}

.doc-box ul li a {
	color: #20257c;
	font-weight: 700;
	position: relative;
	padding: 16px 0px 17px 30px;
	display: block;
	border-bottom: 1px solid #20257c;
}

.doc-box ul li a i {
	position: absolute;
	left: 10px;
	top: 19px;
}

.doc-box ul li:nth-of-type(odd) a {
	background-color: #d9daf5;
}

.doc-box ul li:nth-of-type(even) a {
	background-color: #f4f4fc;
}

/*investment ends*/


.application_box {
	margin-bottom: 30px;
}